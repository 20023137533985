import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" src="https://uidev-in.github.io/exmyb_v2/assets/img/exmyb_logo.svg" sx={{ width:150, ...sx }} />;
}
