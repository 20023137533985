import _ from "lodash";
import {
  emailLogin,
  googleLogin,
  resendEmailOTP,
  signInWithPhoneApi,
  signup,
  validateEmailOTP,
  validateMobileOTP,
  fetchAllForgotPasswordsApi,
  emailVerificationApi,
  mobileVerificationApi,
} from "../../../utils/apis.utils";
import { otpType, strings } from "../../../utils/enum";
import { formatUserResponse } from "../../../utils/global/user.global";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";

import {
  setApplicationForProject,
  setUserProfileDetails,
} from "../user/user.slice";

import { openOTPModal } from "./auth.slice";

export const signUpWithEmail = async (data, thunkApi) => {
  try {
    const { default: defaultAxios } = thunkApi.extra.apiService;
    const response = await defaultAxios.post(signup, {
      ...data,
      ...data.mobile,
      user_type: "client",
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    const responseData = response.data;
    thunkApi.dispatch(setUserProfileDetails(responseData));
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const signInWithGoogle = async ({ token, email }, thunkApi) => {
  try {
    const { default: defaultAxios } = thunkApi.extra.apiService;
    const response = await defaultAxios.post(googleLogin, {
      email: email,
      access_token: token,
      user_type: "client",
      social_type: "google",
    });
    const responseData = response.data;
    const userDetails = formatUserResponse(responseData);
    thunkApi.dispatch(setUserProfileDetails(userDetails));
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return responseData;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const signInWithPhone = async (data, thunkApi) => {
  try {
    const { default: defaultAxios } = thunkApi.extra.apiService;
    const response = await defaultAxios.post(signInWithPhoneApi, {
      ...data,
      user_type: "client",
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    const responseData = response.data;
    thunkApi.dispatch(
      setUserProfileDetails({
        country_code: responseData.country_code,
        mobile_number: responseData.mobile_number,
        session_id: responseData.session_id,
        user_type: responseData.user_type,
      })
    );
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const signInWithEmail = async (data, thunkApi) => {
  try {
    const { default: defaultAxios } = thunkApi.extra.apiService;
    const response = await defaultAxios.post(emailLogin, {
      ...data,
      user_type: "client",
    });

    const responseData = response.data;
    if (_.get(responseData, strings.email_verify)) {
      const userDetails = formatUserResponse(responseData);
      thunkApi.dispatch(setUserProfileDetails(userDetails));
      return responseData;
    } else {
      thunkApi.dispatch(
        setUserProfileDetails({
          email: responseData.email,
          user_type: "client",
        })
      );
      await defaultAxios.post(resendEmailOTP, {
        email: responseData.email,
        user_type: "client",
      });
      thunkApi.dispatch(openOTPModal());

      return Promise.reject();
    }
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const validateOTP = async (data, thunkApi) => {
  try {
    const {
      default: defaultAxios,
      user,
      protectedAuthAxios,
    } = thunkApi.extra.apiService;
    const { type, ...rest } = data;
    let response;
    if (data.verifyOnly) {
      response = await protectedAuthAxios.put(
        type === otpType.email ? emailVerificationApi : mobileVerificationApi,
        rest
      );
    } else {
      response = await defaultAxios.post(
        type === otpType.email ? validateEmailOTP : validateMobileOTP,
        {
          ...rest,
          user_type: "client",
        }
      );
    }
    const responseData = response.data;
    const userDetails = formatUserResponse(responseData);
    thunkApi.dispatch(setUserProfileDetails(userDetails));
    thunkApi.dispatch(
      showSuccessToast({ message: "OTP Verifies Successfully" })
    );
    return responseData;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const validateOTPVerifyOnlyHandler = async (data, thunkApi) => {
  try {
    const { protectedAuthAxios } = thunkApi.extra.apiService;
    const { type, ...rest } = data;
    let response = await protectedAuthAxios.put(
      type === otpType.email ? emailVerificationApi : mobileVerificationApi,
      rest
    );
    const responseData = response.data;
    let message =
      type === otpType.email
        ? "Email Verified Successfully"
        : "Mobile Verified Successfully";
    thunkApi.dispatch(
      showSuccessToast({
        message: message,
        vertical: "top",
        horizontal: "right",
      })
    );
    if (type === otpType.mobile) {
      thunkApi.dispatch(setApplicationForProject());
    }
    return responseData;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const resendOTP = async (data, thunkApi) => {
  try {
    const { default: defaultAxios } = thunkApi.extra.apiService;
    await defaultAxios.post(resendEmailOTP, {
      email: data.email,
      user_type: "client",
    });
    thunkApi.dispatch(showSuccessToast({ message: "Opt Send Successfully" }));
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const forgotPassword = async (email, thunkApi) => {
  try {
    const { default: defaultAxios } = thunkApi.extra.apiService;
    const response = await defaultAxios.post(fetchAllForgotPasswordsApi, {
      ...email,
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    const responseData = response.data;
    return responseData;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const sendEmailVerificationOtpHandler = async (email, thunkApi) => {
  try {
    const { protectedAuthAxios } = thunkApi.extra.apiService;
    await protectedAuthAxios.post(emailVerificationApi, { email });
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const sendMobileVerificationOtpHandler = async (data, thunkApi) => {
  try {
    const { protectedAuthAxios } = thunkApi.extra.apiService;
    const response = await protectedAuthAxios.post(mobileVerificationApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};
