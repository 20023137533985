import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import DashboardApp from "./pages/DashboardApp";
import User from "./pages/UserManagement/User";
import NotFound from "./pages/Page404";
import AllProjects from "./components/_dashboard/projects/AllProjects";
import { Outlet } from "react-router-dom";
import OverView from "./pages/ProjectDetail/components/Overview/OverView";
import ProjectFeedback from "./pages/ProjectDetail/components/ProjectFeedback";
import ProjectDetails from "./pages/ProjectDetail";
import AddUser from "./components/_dashboard/user/AddUser";
import Ticket from "./pages/ProjectDetail/components/Ticket";
import Notification from "./pages/Notification/Notification";
import MyProfile from "./pages/Profile/MyProfile";
import Wallet from "./pages/Wallet/Wallet";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import AuthProtector from "./components/Protector/AuthProtector";
import Faq from "./pages/Faq/Faq";
import withAuth from "./hoc/withAuth";
import Contact from "./pages/Contact/Contact";
import ContactChatTicket from "./pages/Contact/ContactChatTicket";
import NewPassword from "./pages/Auth/NewPassword";
import {
  milestonePath,
  overviewPath,
  proposalPath,
  ratingsPath,
  scopePath,
  supportPath,
  tablePath,
} from "./utils/constant";
import Collections from "./pages/Collection/Collections";
import Charter from "./components/_dashboard/projects/Charter";
import TimelineDashboard from "./components/TimelineDashboard";
import Proposal from "./pages/ProjectDetail/components/Proposal";
import Overview from "./pages/ProjectDetail/components/Overview/OverView";
import Chat from "./pages/Chat/Chat";
import MainChat from "./pages/Chat";

function AppRoutes() {
  return useRoutes([
    {
      path: "/dashboard",
      element: (
        <AuthProtector>
          <DashboardLayout />
        </AuthProtector>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="/dashboard/app" />,
        },
        {
          path: "app",
          element: <DashboardApp />,
        },
        {
          path: "user",
          element: <Outlet />,
          children: [
            { path: "", element: <User /> },
            { path: "createUser", element: <AddUser /> },
          ],
        },
        {
          path: "projects",
          element: <Outlet />,
          children: [
            { path: "", element: <AllProjects /> },
            {
              path: ":id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <ProjectDetails />,
                  children: [
                    { path: overviewPath, element: <Overview /> },
                    { path: proposalPath, element: <Proposal /> },
                    { path: tablePath, element: <TimelineDashboard /> },
                    { path: scopePath, element: <Charter /> },
                    {
                      path: supportPath,
                      element: <Outlet />,
                      children: [
                        { path: "", element: <Ticket /> },
                        { path: ":ticketId", element: <ContactChatTicket /> },
                      ],
                    },
                    { path: milestonePath, element: <Collections /> },
                    { path: ratingsPath, element: <ProjectFeedback /> },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "myProfile",
          element: <MyProfile />,
        },
        { path: "notification", element: <Notification /> },
        { path: "wallet", element: <Wallet /> },
        { path: "overview", element: <OverView /> },
        { path: "project_feedback", element: <ProjectFeedback /> },
        { path: "ticket", element: <Ticket /> },
        { path: "faq", element: <Faq /> },
        { path: "chat", element: <MainChat /> },
        {
          path: "contact_us",
          element: <Outlet />,
          children: [
            { path: "", element: <Contact /> },
            { path: "tickets/:ticketId", element: <ContactChatTicket /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "reset-password", element: <NewPassword /> },
        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

export default withAuth(AppRoutes);
